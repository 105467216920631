import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { chatbotservice } from '../services/chatbot.service';
import { ChatReq, ChatResponse, ChatState } from '../models/chatbot';
import { RootState } from '../store';

export const sendMessage = createAsyncThunk<ChatResponse, string, { state: RootState }>(
  'chat/sendMessage',
  async (message, { getState, rejectWithValue }) => {
    const { chat } = getState();
    const data: ChatReq = {
      user_input: message,
      history: chat.history,
    };
    const response = await chatbotservice(data);
    if (response.isSuccessful) {
      return response.data as ChatResponse;
    } else {
      return rejectWithValue(response.message);
    }
  }
);

const initialState: ChatState = {
  history: [],
  isLoading: false,
  error: null,
};

const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(sendMessage.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(sendMessage.fulfilled, (state, action) => {
        state.isLoading = false;
        // Update the history with the new message
        if (action.payload.history && action.payload.history.length > 0) {
          state.history = action.payload.history;
        } else {
          // If no history is provided, add the new message
          state.history.push({
            query: action.meta.arg,
            answer: action.payload.response
          });
        }
      })
      .addCase(sendMessage.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string || action.error.message || 'An unknown error occurred';
        // Optionally, you can still add the user's message to the history
        state.history.push({ query: action.meta.arg as string });
      });
  },
});

export default chatSlice.reducer;