
import axios from "axios";
import { constants } from "../../config";
export const api = constants.API_BASE_URL;
interface PostParams {
  url: string;
  // headers?: any;
  data?: any;
}

export const getHeaders = async () => {
  return {
    "Access-Control-Allow-Origin": "*",
   // Authorization: `Bearer ${session?.token}`,
   'Authorization': `Bearer ${localStorage.getItem(constants.USER_TOKEN_KEY)}`,
    
  };
};

// export const getPaginationParams = ({ pagenumber = 0, pagesize = 20 }) => {
//   return querystring.stringify({
//     pagenumber: pagenumber + 1,
//     pagesize: pagesize,
//   });
// };
export const postnew = async ({ url, data }: PostParams) => {
  try {
    const response = await axios.post(url, data);
    return response.data;
  } catch (error) {
    console.error('Error in POST request:', error);
    throw error; // or handle the error as needed
  }
};
function stringifyParams(params: Record<string, any>): string {
  return new URLSearchParams(Object.entries(params)).toString();
}

export const error = (e: any) => {
  return { status: "error", isSuccessful: false, message: e, data: null };
};

export const success = (data: any) => {
  return { status: "success", isSuccessful: true, data: data, message: null };
};


type GetRequestDetails = {
  url: string;
  headers?: any;
  params?: any;
};

export const get = async (
  requestDetails: GetRequestDetails = { url: "", params: {}, headers: {} }
) => {
  try {
    const response = await axios.get(requestDetails.url, {
      headers: requestDetails.headers,
      params: requestDetails.params,
    });

    return success(response.data);
  } catch (e) {
    return error(e);
  }
};

type PostRequestDetails = {
  url: string;
  params?: any;
  headers?: any;
  data?: any;
};

export const post = async (
  requestDetails: PostRequestDetails = {
    url: "",
    params: {},
    headers: {},
    data: {},
  }
) => {
  try {
    const stringParams = stringifyParams(requestDetails.params);
    const response = await axios.post(
      `${requestDetails.url}${stringParams != "" ? "?" : ""}${stringParams}`,
      requestDetails.data,
      { headers: { ...requestDetails.headers } }
    );
    return success(response.data);
  } catch (e) {
    return error(e);
  }
};

export const post1 = async (requestDetails: PostRequestDetails = {
  url: '',
  params: {},
  headers: {},
  data: {},
}) => {
  try {
    const stringParams = stringifyParams(requestDetails.params);
    // console.log('Request URL:', `${requestDetails.url}?${stringParams}`);

    const contentType = requestDetails.headers['Content-Type'];
    let headers = {
      ...requestDetails.headers,
      'Content-Type': 'multipart/form-data', // Default to JSON
    };

    if (contentType === 'multipart/form-data') {
      headers['Content-Type'] = 'multipart/form-data';
      // Tell Axios not to serialize the request data if it's FormData
      headers = {
        ...headers,
        transformRequest: (data: any, headers: any) => data,
      };
    }



    const response = await axios.post(
      `${requestDetails.url}${stringParams ? '?' + stringParams : stringParams}`,
      requestDetails.data,
      {
        headers,
      }
    );
    return success(response.data);
  } catch (e) {
    console.log(e);
    return error(e);
  }
};

export const post2 = async (
  requestDetails: PostRequestDetails = {
    url: "",
    params: {},
    headers: {},
    data: {},
  }
) => {
  try {
    const stringParams = stringifyParams(requestDetails.params);
    const response = await axios.post(
      requestDetails.url,
      requestDetails.data,
      { headers: { ...requestDetails.headers } }
    );
    return success(response.data);
  } catch (e) {
    return error(e);
  }
};

export const post3 = async (requestDetails: PostRequestDetails = {
  url: '',
  params: {},
  headers: {},
  data: {},
}) => {
  try {
   
    const stringParams = stringifyParams(requestDetails.params);
    const contentType = requestDetails.headers['Content-Type'] || 'multipart/form-data';
    let headers = {
      ...requestDetails.headers,
      'Content-Type': contentType,
    };

    if (contentType === 'multipart/form-data') {
      headers['Content-Type'] = 'multipart/form-data';
      // Tell Axios not to serialize the request data if it's FormData
      headers = {
        ...headers,
        transformRequest: (data: any, headers: any) => data,
      };
    }else{
      headers['Content-Type'] = 'multipart/form-data';
      // Tell Axios not to serialize the request data if it's FormData
      headers = {
        ...headers,
        transformRequest: (data: any, headers: any) => data,
      };
    }



    const response = await axios.post(
      `${requestDetails.url}${stringParams ? '?' + stringParams : stringParams}`,
      requestDetails.data,
      {
        headers,
      }
    );
    return success(response.data);
  } catch (e) {
    console.log(e);
    return error(e);
  }
};

export const patch2 =async (requestDetails: PostRequestDetails = {
  url: '',
  params: {},
  headers: {},
  data: {},
}) => {
  try {
   
    const stringParams = stringifyParams(requestDetails.params);
    const contentType = requestDetails.headers['Content-Type'] || 'multipart/form-data';
    let headers = {
      ...requestDetails.headers,
      'Content-Type': contentType,
    };

    if (contentType === 'multipart/form-data') {
      headers['Content-Type'] = 'multipart/form-data';
      // Tell Axios not to serialize the request data if it's FormData
      headers = {
        ...headers,
        transformRequest: (data: any, headers: any) => data,
      };
    }else{
      headers['Content-Type'] = 'multipart/form-data';
      // Tell Axios not to serialize the request data if it's FormData
      headers = {
        ...headers,
        transformRequest: (data: any, headers: any) => data,
      };
    }



    const response = await axios.patch(
      `${requestDetails.url}${stringParams ? '?' + stringParams : stringParams}`,
      requestDetails.data,
      {
        headers,
      }
    );
    return success(response.data);
  } catch (e) {
    console.log(e);
    return error(e);
  }
}





type DownloadRequestDetails = {
  url: string;
  params?: any;
  data?: any;
  headers?: any;
};

export const download = async (
  requestDetails: DownloadRequestDetails = {
    url: "",
    params: {},
    data: {},
    headers: {},
  }
) => {
  try {
    const stringParams = stringifyParams(requestDetails.params);
    const response = await axios.post(
      `${requestDetails.url}?${stringParams}`,
      requestDetails.data,
      {
        ...requestDetails.headers,
        responseType: "blob",
      }
    );
    return success(response.data);
  } catch (e) {
    return error(e);
  }
};

type UpdateRequestDetails = {
  url: string;
  params?: any;
  data?: any;
  headers?: any;
};
export const getLocalisationHeaders = () => {
  // TODO get locale from browser preferences
  return {
    // 'X-Languages': i18next.language,
    // 'X-Flatten': true,
  };
};

export const update = async (
  requestDetails: UpdateRequestDetails = {
    url: "",
    params: {},
    data: {},
    headers: {},
  }
) => {
  try {
    const stringParams = stringifyParams(requestDetails.params);
    const response = await axios.put(
      requestDetails.url + `?${stringParams}`,
      requestDetails.data,
      {
        headers: { ...requestDetails.headers },
      }
    );
    return success(response.data);
  } catch (e) {
    return error(e);
  }
};

type patchRequestDetails = {
  url: string;
  params?: any;
  data?: any;
  headers?: any;
};

export const patch = async (
  requestDetails: patchRequestDetails = {
    url: "",
    params: {},
    data: {},
    headers: {},
  }
) => {
  try {
    const response = await axios.patch(
      requestDetails.url,
      requestDetails.data,
      {
        headers: { ...requestDetails.headers },
      }
    );
    return success(response.data);
  } catch (e) {
    return error(e);
  }
};

type DeleteRequestDetails = {
  url: string;
  params?: any;
  headers?: any;
};

export const del = async (
  requestDetails: DeleteRequestDetails = {
    url: "",
    params: {},
    headers: {},
  }
) => {
  try {
    const stringParams = stringifyParams(requestDetails.params);
    const response = await axios.delete(
      requestDetails.url + `?${stringParams}`,
      {
        ...requestDetails.headers,
      }
    );
    return success(response.data);
  } catch (e) {
    return error(e);
  }
};
