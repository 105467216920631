import chat from "./common/chat.svg";
import arrowRightBlack from "./common/arrowRight.svg";

export const chatwindow = {
  chat,
};

export const common = {
  arrowRightBlack,
};
