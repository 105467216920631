declare global {
  interface Window {
      _env_: { [key: string]: string | undefined }; 
  }
}

export const constants = {
  API_BASE_URL: 'https://mlaas.styava.dev', 
  API_ENDPOINT: process.env.END_POINT as string | undefined, 
  USER_TOKEN_KEY: "USER_TOKEN_KEY",
};

export default constants;