import React, { useState, useEffect, useRef } from 'react';
import { Button } from '../ui/button';
import { Input } from '../ui/input';
import { chatwindow, common } from '../../assets/icon';
import { ScrollArea } from '../ui/scrollarea';
import constants from '../../config';

const Chatbot: React.FC = () => {
  const [input, setInput] = useState('');
  const [history, setHistory] = useState<{ query: string, answer?: string }[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [ws, setWs] = useState<WebSocket | null>(null);
  const [isOpen, setIsOpen] = useState(true);
  
  useEffect(() => {
    const connectWebSocket = () => {
      if (ws) {
        ws.close();
      }
      const socket = new WebSocket(`ws://172.214.42.4:8000/ws`);
  
      socket.onopen = () => console.log("WebSocket connected");
  
      socket.onmessage = (event) => {
        try {
          // Attempt to parse the message as JSON
          const data = JSON.parse(event.data);
          
          setHistory((prevHistory) => [
            ...prevHistory,
            { query: data.query, answer: data.answer },
          ]);
        } catch (e) {
          // If parsing fails, assume the message is plain text
          console.error("Error parsing JSON:", e);
      
          setHistory((prevHistory) => [
            ...prevHistory,
            { query: "Bot Message", answer: event.data }, // Adjust as needed
          ]);
        } finally {
          setIsLoading(false);
        }
      };
      socket.onerror = (event) => {
        console.error("WebSocket error", event);
        setError("WebSocket error occurred.");
        setIsLoading(false);
      };
  
      socket.onclose = (event) => {
        console.warn("WebSocket closed", event);
        // Attempt to reconnect after a delay
        setTimeout(() => connectWebSocket(), 5000);
      };
  
      setWs(socket);
    };
  
    connectWebSocket();
  
    return () => {
      if (ws) {
        ws.close();
      }
    };
  }, []);

  const toggleChat = () => setIsOpen(!isOpen);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const scrollAreaRef = useRef<HTMLDivElement>(null);
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  useEffect(scrollToBottom, [history]);
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (input.trim() && ws && ws.readyState === WebSocket.OPEN) {
      setHistory((prevHistory) => [
        ...prevHistory,
        { query: input },
      ]);
      ws.send(JSON.stringify({ query: input }));
      setInput('');
      setIsLoading(true);
      setError(null);
    } else {
      setError("WebSocket is not connected.");
    }
  };
  const renderTextWithLinks = (text:string) => {
    // Updated regex to match URLs with or without parentheses
    const urlRegex = /(\(?(https?:\/\/[^\s)]+)\)?)/g;
    const parts = text.split(urlRegex);
  
    return parts.map((part, index) => {
      if (part.match(urlRegex)) {
        // Remove parentheses from the URL if they exist
        const url = part.replace(/^\(|\)$/g, '');
        return (
          <a
            key={index}
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 hover:underline"
          >
            {part}
          </a>
        );
      }
      return part;
    });
  };
  return (
    <div className="fixed bottom-14 right-24 z-50">
      {isOpen && (
        <div className="bg-slate-100 rounded-lg shadow-lg w-96 h-[450px] mb-4 flex flex-col">
          <div className="bg-primary text-white p-4 rounded-t-lg">
            <h3 className="font-bold">Chat with us</h3>
          </div>
          <div className="chatbot py-2 px-5 h-screen flex flex-col">
            <ScrollArea className="flex-grow w-full overflow-y-auto" ref={scrollAreaRef}>
              <div className="chat-history h-64 flex flex-col overflow-y-auto gap-4">
                {history.map((message, index) => (
                  <div key={index}>
                    {message.query !== "Bot Message" ? (
                <div className="flex justify-end">
              <div className={`${message.query !== "Bot Message" ? 'self-end' : 'self-start'} inline-block p-3 rounded-lg bg-red-500 text-white`}>

                {message.query}
                </div>
              </div>
            ) : (
              <>
                <div className="inline-block p-3 rounded-lg bg-gray-200 text-black">
                {message.answer && renderTextWithLinks(message.answer)}
                </div>
              </>
            )}
                   
                  </div>
                ))}
                {isLoading && <div className="message loading">Bot is typing...</div>}
                {error && <div className="error">{error}</div>}
              <div ref={messagesEndRef} />
              </div>
            </ScrollArea>
            <form className='shadow-t-lg flex-1' onSubmit={handleSubmit}>
              <div className="flex">
                <Input
                  type="text"
                  value={input}
                  className="flex-grow mr-2 py-5 rounded-full"
                  onChange={(e) => setInput(e.target.value)}
                  placeholder="Type your message..."
                  disabled={isLoading}
                />
              </div>
              <div className="flex -mt-[41px] justify-end mr-2">
                <Button className='bg-secondary rounded-e-full' type="submit">
                  <img src={common.arrowRightBlack} alt='Send' />
                </Button>
              </div>
            </form>
          </div>
        </div>
      )}
      <div className='flex justify-end'>
        <Button
          onClick={toggleChat}
          className="rounded-full w-14 h-14 flex items-center justify-center shadow-lg"
        >
          <img src={chatwindow.chat} alt='Chat' />
        </Button>
      </div>
    </div>
  );
};

export default Chatbot;