import React from 'react';
import logo from './logo.svg';
import './App.css';
import Chatbot from './components/common/Chatbot';
import one from "./assets/image 1.png"
import two from "./assets/image 2.png"
function App() {
  return (
    <div className=''>
      <img src={one}alt='' className='object-cover w-screen'/>
      <img src={two}alt='' className='object-cover w-screen'/>
      <div className=''>
      <Chatbot/>
      </div>
    </div>
  );
}

export default App;
