import { constants } from "../../config";
import { ChatReq } from "../models/chatbot";
import { getHeaders, post, postnew } from "./services.common";

export const chatbotservice = async (data: ChatReq) => {
    console.log("chatbotservice called with", data);
    const url = `${constants.API_BASE_URL}/robibot/chat`;
    const headers = await getHeaders();
    console.log("Making API call to", url);

    try {
        const response = await postnew({
            url,
            // headers,
            data: { ...data },
        });

        console.log("API response", response);

        // Check if the response contains the expected data structure
        if (response && response.history && response.response) {
            return {
                isSuccessful: true,
                data: response,
                message: '',
            };
        } else {
            console.error("Unexpected API response structure:", response);
            return {
                isSuccessful: false,
                data: { history: [], response: '' },
                message: "Unexpected API response structure",
            };
        }
    } catch (error) {
        console.error("Error in chatbotservice:", error);
        return {
            isSuccessful: false,
            data: { history: [], response: '' },
            message: error instanceof Error ? error.message : "An unknown error occurred",
        };
    }
};
